import React from 'react';
import Issue from './Issue';



function IssueFeed({deleteIssue, isAdmin, items, onMarkAsCompleted, fetchIssues, selectedBus, order}) {
  let orderedItems;

  if(order === "Oldest"){
    orderedItems = items
  }

  if(order === "Newest"){
    orderedItems = [...items].reverse();
  }


 
   
  return (
    <div className="space-y-6">
      {orderedItems.map((issue, index) => (

        selectedBus === "LPT" && !issue.isCompleted && issue.LPT === true ? (
          <Issue 
            deleteIssue={deleteIssue} 
            isAdmin={isAdmin} 
            key={index} 
            issue={issue} 
            onMarkAsCompleted={onMarkAsCompleted} 
            fetchIssues={fetchIssues} 
          />
        ) : selectedBus === "SFF" && !issue.isCompleted && issue.SFF === true ? (
          <Issue 
            deleteIssue={deleteIssue} 
            isAdmin={isAdmin} 
            key={index} 
            issue={issue} 
            onMarkAsCompleted={onMarkAsCompleted} 
            fetchIssues={fetchIssues} 
          />
        ) : selectedBus === "ALL" && !issue.isCompleted ? (
          <Issue 
            deleteIssue={deleteIssue} 
            isAdmin={isAdmin} 
            key={index} 
            issue={issue} 
            onMarkAsCompleted={onMarkAsCompleted} 
            fetchIssues={fetchIssues} 
          />
        ) : null
        
      ))}
    </div>
  )
}

export default IssueFeed
