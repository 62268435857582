import React from 'react'
import CompletedIssueFeed from './CompletedIssueFeed'
import { useNavigate } from 'react-router-dom';

function Logs({items, onMarkAsCompleted}) {
  const navigate = useNavigate();

  function handleClick() {
    navigate('/');
  }
  return (
    
  <div className="mt-4 w-full max-w-2xl mx-auto bg-green-100 dark:bg-gray-800 p-6 rounded-lg">
  <div className="flex justify-between items-center mb-6">
    <h1 className="text-3xl font-bold dark:text-white">Completed Log</h1>
    <button onClick={handleClick} class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1.5 px-4 border border-gray-400 rounded shadow">
       Back 
    </button>
     </div>

      <CompletedIssueFeed onMarkAsCompleted={onMarkAsCompleted} items={items} />

    </div>
    
    
  )
}

export default Logs
