import React, { useState } from 'react';

function LoginForm({setIsSuperUser, setIsAuthenticated, setIsAdmin}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (event) => {

    const loginData = {username, password}

    event.preventDefault();

    try {
        const response = await fetch(`${apiUrl}/api/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData),
          credentials: 'include', 
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const responseData = await response.json();
      
        setIsAuthenticated(responseData.authenticate);
        setIsAdmin(responseData.user.isAdmin);
        setIsSuperUser(responseData.user.isSuperUser);

        console.log(responseData.user.isAdmin, "response")
        
      } catch (error) {
        console.error('Login failed:', error);
 
      }
  };

  return (
  <div>
  <h1 className="text-3xl font-bold dark:text-white mb-6">Pass Down Log</h1>
    <form className='flex justify-center items-center flex-col mt-10' onSubmit={handleSubmit}>
      
  <div className="flex flex-col justify-center w-1/2 border text-card-foreground mb-6 bg-white dark:bg-gray-900 shadow-lg rounded-lg" data-v0-t="card">
  <h1 className="p-6 pb-0 text-xl font-bold dark:text-white">Login</h1>
    <div className="p-6 w-full flex flex-col justify-center">
      
      {/* Username Input */}
      <input
        type="text"
        id="username"
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        placeholder="Username..."
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />

      {/* Password Input */}
      <input
        type="password"
        id="password"
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        placeholder="Password..."
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          type="submit"
          className="bg-black hover:bg-gray-100 hover:text-black text-white font-semibold py-1.5 px-4 border border-gray-400 rounded shadow"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</form>
</div>
  );
}

export default LoginForm;