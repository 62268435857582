import React, { useState } from 'react';
import IssueSubmissionForm from './features/Issues/IssueSubmissionForm';
import IssueFeed from './features/Issues/IssueFeed';
import { useNavigate } from 'react-router-dom';
import LoginForm from './features/Users/Login';
import { useEffect } from 'react';
import RegForm from './features/Admin/Register';
import Sort from './features/Sort';




function Home({isSuperUser, setIsSuperUser, deleteIssue, setIsAdmin, isAdmin, isAuthenticated, setIsAuthenticated, logout, items, setItems, onMarkAsCompleted, fetchIssues}) { 
  
  let navigate = useNavigate();

  function handleClick() {
    navigate('/Logs');
  }

  function handleClick1() {
    navigate('/Admin');
  }
  const [selectedBus, setSelectedBus] = useState('ALL');
  const [order, setOrder] = useState('Oldest');


  return (
    

    <div className="mt-4 w-full max-w-2xl mx-auto bg-gray-100 dark:bg-gray-800 p-6 rounded-lg">

  <div>
 
      
      {isAuthenticated ? 
      <div>
      <div className="flex justify-between flex-wrap items-center mb-6 gap-4">
    <h1 className="text-3xl font-bold dark:text-white">Pass Down Log</h1>
    <div className="flex flex-row">
    <button onClick={handleClick} className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1.5 px-4 border border-gray-400 rounded shadow">
      View Completed
    </button>
    { isSuperUser ?
    <button onClick={handleClick1} className="bg-white ml-2 hover:bg-gray-100 text-gray-800 font-semibold py-1.5 px-4 border border-gray-400 rounded shadow">
      Admin
    </button> : <div></div>
    }
    <button onClick={logout} className="bg-white ml-2 hover:bg-gray-100 text-gray-800 font-semibold py-1.5 px-4 border border-gray-400 rounded shadow">
      Logout
    </button>
    </div>
  </div>
      <IssueSubmissionForm fetchIssues={fetchIssues} items={items} />
      <Sort selectedBus={selectedBus} setSelectedBus={setSelectedBus} order={order} setOrder={setOrder} />
      <IssueFeed order={order} selectedBus={selectedBus} deleteIssue={deleteIssue} isAdmin={isAdmin} onMarkAsCompleted={onMarkAsCompleted} items={items} fetchIssues={fetchIssues}/> 
      </div>
      :
      <div>
        <LoginForm setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} setIsSuperUser={setIsSuperUser} />
      </div>
      }

      </div>
    
    </div>
  )
}

export default Home
