import React from 'react'
import User from './User'




function UserList({users, userData}) {
    


  return (
    <div className="flex flex-col justify-center items-center mt-4 mb-10">
    <div className=" border text-card-foreground bg-white dark:bg-gray-900 shadow-lg rounded-lg">
      <h1 className="p-6 pb-0 text-xl font-bold dark:text-white">Users</h1>
      <div className="p-6">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="py-3 px-6">Username</th>
              <th className="py-3 px-6">Admin</th>
              <th className="py-3 px-6">Remove User</th>
            </tr>
          </thead>
          <tbody>
            {users.map((x) => ( 
               

                <User x={x} userData={userData} />
            ))}
      
      
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default UserList
