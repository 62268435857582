import React from 'react'
import { useState } from 'react';
import Note from './Note';


function Issue({deleteIssue, isAdmin, issue, onMarkAsCompleted,fetchIssues}) {

    function generateString(a, b) {
        if (a && b) {
          return "Launch Personal Training & Super Future Fitness";
        } else if (a) {
          return "Launch Personal Training";
        } else if (b) {
          return "Super Future Fitness";
        } else {
          return "";
        }
      }

      const [showReplyBox, setShowReplyBox] = useState(false);
      const [reply, setReply] = useState('');

      const handleAddNote = () => {
        setShowReplyBox(true); 
      };

      const handleReplyChange = (e) => {
        setReply(e.target.value); 
      };

      const submitReply = () => {
        issue.replies.push(reply)
        console.log('Reply:', reply);

        if (!reply) {
          alert("Please fill text field");
          return; 
        }

        const issueId = issue._id;

        fetch(`/api/issues/add-reply/${issueId}`, {
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ reply: reply })
        })
        .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setReply('');
            setShowReplyBox(false);
          })
          .catch(error => console.error('Error:', error));
        };

        const url = `https://app.clubready.com/admin/selectcustomer.asp?id=${issue.memberId}`;

        
      
   
  return (
    
      <div className="border text-card-foreground bg-white dark:bg-gray-900 shadow-lg rounded-lg" data-v0-t="card">
      <div className="flex flex-col space-y-1.5 p-6">
        <div className="flex justify-between flex-wrap">
        <div className="flex flex-col gap-1">
          <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight">Issue by {issue.employeeName}</h3>
           <p className="text-sm text-gray-500 dark:text-gray-400">Customer: {issue.customerName}</p>
           <a href={url} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-500 dark:text-gray-400">Member ID: {issue.memberId}</a>
          <p className="text-sm text-gray-500 dark:text-gray-400">Issue Type: {generateString(issue.LPT, issue.SFF)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500 dark:text-gray-400">{issue.issueDate}</p>
          
          </div>
        </div>
       
      </div>
      <div className="p-6">
        <p>{issue.issueDescription}</p>
      </div>
      
      {issue.replies.length > 0 ? ( issue.replies.map((note, index) => (
        <Note key={index} note={note} /> 
        
        ))
    ) : (
       <div></div>
    )}

      {showReplyBox && (
        <div className="p-6">
          <textarea
            className="w-full rounded-md border p-2"
            value={reply}
            onChange={handleReplyChange}
            placeholder="Type your reply here..."
          ></textarea>
          <button onClick={submitReply} className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1.5 px-4 rounded">
            Submit
          </button>
        </div>
      )}
      <div className="p-6 flex justify-between items-center">
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={issue.isCompleted}
            onChange={()=> onMarkAsCompleted(issue._id)}
            className="hover:cursor-pointer peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
            
          ></input>
          <label htmlFor="issue-1" className="ml-2">
            Mark as completed
          </label>
        </div>
        <div className="flex items-center">
          {/* <button class="mr-2 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1.5 px-4 border border-gray-400 rounded shadow">
            Edit
          </button> */}
          <button 
          onClick={handleAddNote}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1.5 px-4 border border-gray-400 rounded shadow">
            Add Note
          </button>
          {isAdmin ?  <button 
          onClick={() => deleteIssue(issue._id)}
          className="bg-white hover:bg-red-100 ml-4 text-red-800 font-semibold py-1.5 px-4 border border-red-400 rounded shadow">
            Delete
          </button> : <div></div>}
        </div>
      </div>
      
    </div>

    
  )
}

export default Issue
