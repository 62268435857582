import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';



function Sort({selectedBus, setSelectedBus, order, setOrder}) {


  const handleChange = (event) => { 
  
    setSelectedBus(event.target.value);
   
     event.preventDefault();
  };

  const handleChange1 = (event) => { 
  
    setOrder(event.target.value);
   
     event.preventDefault();
  };
  const handleSubmit = (event) => {
    
  };

  useEffect(() => {
   
        console.log(selectedBus);
    
  }, [selectedBus, order])
  


  return (
    <div className='flex flex-row px-8 gap-4'>
      <form onSubmit={handleSubmit}>
      <label htmlFor="business">Sort By Business:</label>
      <select name="Business" id="Business" value={selectedBus} onChange={handleChange}>
        <option value="ALL">ALL</option>
        <option value="SFF">SFF</option>
        <option value="LPT">LPT</option>
      </select>
    </form>
    <form onSubmit={handleSubmit}>
      <label htmlFor="business">Sort By Date:</label>
      <select name="order" id="order" value={order} onChange={handleChange1}>
        <option value="Oldest">Oldest</option>
        <option value="Newest">Newest</option>
      </select>
      
      <br /><br />
    </form>
    </div>
  )
}

export default Sort