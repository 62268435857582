import React from 'react'

function User({x, userData}) {

    const deleteUser = async () => {
        try{
        const response = await fetch('/api/deleteUser', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify({username : x.username}),
            credentials: 'include'
        });


        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          userData();
          const responseData = await response.json();

          console.log(responseData)

        } catch (error) {
            console.error('Login failed:', error);
     
          }
    }


  return (
    
         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="py-4 px-6">{x.username}</td>
              <td className="py-4 px-6">{x.isAdmin ? <p>Yes</p> : <p>No</p>}</td>
              <td className="py-4 px-6"><a onClick={deleteUser} className='hover:cursor-pointer text-red-400'>DELETE</a></td>
            </tr>
      
    
  )
}

export default User
