import React, { useState } from 'react';
import { useEffect } from 'react';

function IssueSubmissionForm({fetchIssues, items}) {
  const [employeeName, setEmployeeName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [SFF, setSFF] = useState(false);
  const [LPT, setLPT] = useState(false);
  const [memberId, setMemberId] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!employeeName || !customerName || !issueDescription || (!SFF && !LPT)) {
      alert("Please fill all text fields and check at least one checkbox.");
      return; // Prevent form submission
    }

    // Process form data here
    // You can send the data to your backend server, for instance
    //console.log({ employeeName, customerName, issueDescription, issueDate });

    
   

    // onAddItem(newObj)

    
    fetch('/api/submit-data', {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(
            {
                "employeeName": employeeName,
                "customerName": customerName,
                "memberId": memberId,
                "issueDescription":issueDescription,
                "issueDate": new Date().toDateString(),
                "LPT": LPT,
                "SFF": SFF
            }
        )})
    .then(response => response.text()) // parse JSON from response
    .then(data =>{ console.log(data);
    fetchIssues()})
    .catch(error => console.error('Error:', error)
    
    );
 

    // Reset form fields
    setEmployeeName('');
    setCustomerName('');
    setIssueDescription('');
    setIssueDate('');
    setMemberId('')
    setLPT(false);
    setSFF(false);
  };




  return (
    <form onSubmit={handleSubmit}>
    <div className="border text-card-foreground mb-6 bg-white dark:bg-gray-900 shadow-lg rounded-lg" data-v0-t="card">
    <div className="p-6">
      <input
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        id="name"
        placeholder="Your Name..."
        value={employeeName}
        onChange={(e) => setEmployeeName(e.target.value)}
        required
      />
      <input
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        id="customer-name"
        placeholder="Customer Name..."
        value={customerName}
        onChange={(e)=> setCustomerName(e.target.value)}
        required
      />
            <input
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        id="member-id"
        placeholder="Member Id..."
        value={memberId}
        onChange={(e)=> setMemberId(e.target.value)}
        
      />
     
      <textarea
        className="flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        id="new-issue"
        placeholder="Describe the issue..."
        value={issueDescription}
        onChange={(e)=> setIssueDescription(e.target.value)}
        required
      ></textarea>
      
      <div className=" flex pb-2 gap-x-4 items-center">
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={SFF}
            onChange={()=> {setSFF(!SFF); console.log(SFF)}}
            className="hover:cursor-pointer peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
            
          ></input>
          <label htmlFor="issue-1" className="ml-2">
            Super Future Fitness
          </label>
          
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={LPT}
            onChange={()=> setLPT(!LPT)}
            className="hover:cursor-pointer peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
            
          ></input>
          <label htmlFor="issue-1" className="ml-2">
            Launch Personal Training
          </label>
          
        </div>
       
      </div>
      <div className="flex justify-end">
        <button
          className="bg-black hover:bg-gray-100 hover:text-black text-white font-semibold py-1.5 mt-2 px-3 border border-gray-400 rounded shadow"
          type="submit"
        >
          Post Issue
        </button>
      </div>
    </div>
  </div>
    </form>
   
  );
}

export default IssueSubmissionForm;
