import React from 'react'

function Note({note}) {
  return (
    
      <div className="pl-10">
        <p>Note: {note}</p>
      </div>
    
  )
}

export default Note
