
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from './Home';
import Logs from './features/Issues/Logs';
import { Navigate } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import Admin from "./features/Admin/Admin";


function App()  {


  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [items, setItems] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin ] = useState();
  const [isSuperUser, setIsSuperUser] = useState();

  const deleteIssue = async (issueId) => {
    try {
        const response = await fetch(`${apiUrl}/api/deleteIssue`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({issueId: issueId}),
          credentials: 'include', 
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        fetchIssues();
        console.log(responseData)
      
        
      } catch (error) {
        console.error('Login failed:', error);
 
      }
  };

  const logout = () => {
    setIsAuthenticated(false);
    fetch(`${apiUrl}/api/logout` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
  }

  
  const checkAuthentication = () => {
  
    fetch(`${apiUrl}/api/protected-route`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then(response => response.json())
      .then(data =>{  data.authenticated ? setIsAuthenticated(true): setIsAuthenticated(false);
        setIsAdmin(data.user.isAdmin);
        setIsSuperUser(data.user.isSuperUser);
        })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    };


  const fetchIssues = () => {
    fetch(`${apiUrl}/api/issues`)
      .then(response => response.json())
      .then(data => { setItems(data); })
      .catch(error => console.error('Error:', error));

  };

  const onMarkAsCompleted = (id) => {
    fetch(`${apiUrl}/api/issues/mark-completed/${id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {fetchIssues();
      if (!response.ok) {
        
        throw new Error('Network response was not ok');
      }
     
    })
    .catch(error => console.error('Error:', error));
    
  };




  useEffect(() => {
    checkAuthentication();
    fetchIssues();
    
  }, []);

  const ProtectedRoute = ({ isAuthenticated, children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }
    return children
  }

  const AdminRoute = ({ isSuperUser, children }) => {
    if (!isSuperUser) {
      return <Navigate to="/" />;
    }
    return children
  }


  
  if (isLoading) {
    return <div>Loading...</div>; 
  }



  return ( 
  <div className="App">
  


    <Router>
      <Routes>
        <Route path="/" element={<Home isSuperUser={isSuperUser} setIsSuperUser={setIsSuperUser} deleteIssue={deleteIssue} setIsAdmin={setIsAdmin} isAdmin={isAdmin} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} logout={logout} items={items}  setItems={setItems} onMarkAsCompleted={onMarkAsCompleted} fetchIssues={fetchIssues}/>} />
        
        <Route 
        path="/Logs" 
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Logs onMarkAsCompleted={onMarkAsCompleted} items={items} />
          </ProtectedRoute>

        }/>
        <Route 
        path="/Admin" 
        element={
          <AdminRoute isSuperUser={isSuperUser}>
            <Admin />
          </AdminRoute>
        }/>

      </Routes>

      
    </Router>


    </div>

    
  );
}

export default App;
