import React from 'react';
import { useState } from 'react';

function RegForm({userData}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passIsAdmin, setPassIsAdmin] = useState(false);
  const [email, setEmail] = useState('');
  const [lptEmails, setLptEmails] = useState(false);
  const [sffEmails, setSffEmails] = useState(false);

  function validateEmail(email) {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  }

  const handleSubmit = async (event) => {

    const regData = {email, username, password, passIsAdmin, lptEmails, sffEmails}

    event.preventDefault();

    if(validateEmail(email) ) {

    try {
        const response = await fetch('/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(regData),
          credentials: 'include', 
        });

        
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        userData();
        const responseData = await response.json();
        console.log(responseData.msg)
      
        
      } catch (error) {
        console.error('Login failed:', error);
 
      } } else { alert("email not valid")}
  };

  return (
  <div>

    <form className='flex justify-center items-center flex-col mt-10 p-6' onSubmit={handleSubmit}>
      
  <div className="flex flex-col justify-center border text-card-foreground mb-6 bg-white dark:bg-gray-900 shadow-lg rounded-lg" data-v0-t="card">
  <h1 className="p-6 pb-0 text-xl font-bold dark:text-white">Register a new user</h1>
    <div className="p-6 w-full flex flex-col justify-center">
      
      {/* Username Input */}
      <input
        type="text"
        id="username"
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        placeholder="Username..."
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />

<input
        type="text"
        id="email"
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        placeholder="Email..."
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />

      {/* Password Input */}
      <input
        type="password"
        id="password"
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mb-4"
        placeholder="Password..."
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />

<div className="flex items-center ml-1">
          <input
            type="checkbox"
            checked={passIsAdmin}
            onChange= {() => {setPassIsAdmin(!passIsAdmin); console.log("admin", passIsAdmin)}}
            className="hover:cursor-pointer peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
            
          ></input>
          <label htmlFor="issue-1" className="ml-2">
            Make Admin
          </label>
          
        </div>

        <div className="flex items-center ml-1">
          <input
            type="checkbox"
            checked={lptEmails}
            onChange= {() => {setLptEmails(!lptEmails); console.log("lpt", lptEmails)}}
            className="hover:cursor-pointer peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
            
          ></input>
          <label htmlFor="issue-1" className="ml-2">
            Recieve LPT Emails 
          </label>
          
        </div>

        <div className="flex items-center ml-1">
          <input
            type="checkbox"
            checked={sffEmails}
            onChange= {() => {setSffEmails(!sffEmails); console.log("sff", sffEmails)}}
            className="hover:cursor-pointer peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
            
          ></input>
          <label htmlFor="issue-1" className="ml-2">
            Recieve SFF Emails 
          </label>
          
        </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          type="submit"
          className="mt-2 bg-black hover:bg-gray-100 hover:text-black text-white font-semibold py-1.5 px-4 border border-gray-400 rounded shadow"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</form>
</div>
  );
}

export default RegForm;