import React from 'react'
import RegForm from './Register'
import UserList from './UserList'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


function Admin() {
    const [users, setUsers] = useState([])

    let Navigate = useNavigate();

    const handleClick = () => {
        Navigate('/')
    }

    const userData = () => fetch('/api/users')
          .then(response => response.json())
          .then(data => { setUsers(data.users); })
          .catch(error => console.error('Error:', error));
    
          
    
          useEffect(() => {
            userData();
            
          }, []);

  return (
    <div>
        <div className='flex flex-row flex-wrap justify-between mt-10 gap-6 mx-6'>
      <h1 className="text-3xl font-bold dark:text-white ml-6">Pass Down Log Admin</h1>
      <button onClick={handleClick} className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1.5 px-4 mr-6 border border-gray-400 rounded shadow">
      Back
    </button>
      </div>
      <RegForm userData={userData}/>
      <UserList users={users} userData={userData}/>
    </div>
  )
}

export default Admin
