import React from 'react';
import Issue from './Issue';



function CompletedIssueFeed({items, onMarkAsCompleted}) {
  return (
    <div className="space-y-6">
      {items.map((issue, index) => (

        issue.isCompleted  && 
        <Issue key={index} issue={issue} onMarkAsCompleted={onMarkAsCompleted}/>
      
        
      ))}
    </div>
  )
}

export default CompletedIssueFeed